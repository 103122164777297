import axios from "axios";
import analytics from "./analytics";
import * as configs from "../configs";

const api = getAPI();

function getAPI()
{
	return axios.create({
		baseURL: configs.SERVER.HOSTNAME
	});
}

function whatsAppSend(number: string = "5511992449217")
{
	var callback = function () {
		window.open(`https://api.whatsapp.com/send?phone=${number}&text=Clique%20no%20bot%C3%A3o%20ao%20lado%20para%20falar%20com%20o%20GrupoD%C3%AAOuvidos%20%3E%3E%3E`)
	};
	
	analytics.event('conversion', `${analytics.GA_TRACKING_ID}/mFjDCIiA1awDEICmxooD`, callback);
}

function emailSend(email: string = "contato@grupodeouvidos.com.br")
{
	// ENVIAR CONVERSAO ?
	window.open(`mailto:${email}`);
}

function phoneSend(number: string)
{
	// ENVIAR CONVERSAO?
	window.open(`tel:${number}`)
}

export default {
	api,
	whatsAppSend,
	emailSend,
	phoneSend
}