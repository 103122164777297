import { useContext } from "react";
import { ApplicationContext } from "src/client/contexts/ApplicationContext";
import { Wrapper } from "./styles";
import { Content } from "../styles";
import { Button } from "src/client/styles/layout";

export default function UpgradeDetails() {

  const { conversations } = useContext(ApplicationContext);
  const data = conversations.data;

  if(conversations.section !== "upgrade") return <></>;

  return <Content className="active">
    <Wrapper>
      <h2>Solicitar demonstração</h2>
      <p>Solicite uma demonstração completa e obtenha acesso a recursos exclusivos.</p>
      <Button onClick={() => window.open("https://wa.me/5513997742436?text=Olá Saturnia, gostaria de solicitar uma demonstração completa do sistema!")} whatsapp={true} min={true}>Solicitar agora</Button>
    </Wrapper>
  </Content>
}
