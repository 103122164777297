import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button, Container } from "src/client/styles/layout";
import Image1 from "src/client/assets/images/component-why-grupodeouvidos-1.jpg";
import Image2 from "src/client/assets/images/component-why-grupodeouvidos-2.jpg";
import Image3 from "src/client/assets/images/component-why-grupodeouvidos-3.jpg";

const SectionWrapper = styled.section`
	padding: 80px 0;
	background-color: #fff;
	text-align: center;

	.container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

const Title = styled.h2`
	font-size: 2.5rem;
	margin: 0 0 60px;

	span {
		font-weight: 800;
		color: var(--color-primary-light);
	}

	@media (max-width: 1400px) {
		font-size: 2rem;
	}

	@media (max-width: 991px) {
		max-width: 300px;
	}

	@media (max-width: 768px) {
		font-size: 2rem;
	}
`;

const ItemsWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;

	@media (max-width: 992px) {
		grid-template-columns: 1fr;
	}
`;

const Item = styled.div`
	background-color: #fff;
	border-radius: 14px;
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
	background-color: var(--color-primary-light);
	overflow: hidden;
	text-align: center;

	img {
		width: 100%;
		height: auto;
	}

	div {
		padding: 30px 40px;
		color: var(--color-white);
		font-weight: 800;
		font-size: 1.3rem;
		
		span {
			margin: 0 auto;
			display: block;
			max-width: 360px;
		}
	}

	@media (max-width: 1400px) {
		div {
			padding: 30px 20px;
			color: var(--color-white);
			font-size: 1.2rem;
		}
	}

	@media (max-width: 1200px) {
		div {
			font-size: 1.2rem;
			padding: 20px;
			
			span {
				max-width: 260px;
			}
		}
	}
`;

const Subtitle = styled.p`
	margin: 50px 0px;
	font-size: 1.3rem;
	color: #555;
	max-width: 740px;

	@media (max-width: 1400px) {
		font-size: 1.2rem;
		max-width: 680px;
	}
`;


export default function WhyChooseUsSection() {

	const navigate = useNavigate();

	return (
		<SectionWrapper>
			<Container className="container">
				<Title>Por que escolher o <span>Grupo Dê Ouvidos?</span></Title>
				<ItemsWrapper>
					<Item>
						<img src={Image1} alt="Mais de 9.500 pessoas atendidas em 2023" />
						<div><span>MAIS DE 9.500 PESSOAS ATENDIDAS EM 2023</span></div>
					</Item>
					<Item>
						<img src={Image2} alt="Experiência e compromisso há mais de 25 anos" />
						<div><span>MAIS DE 25 ANOS DE EXPERIÊNCIA</span></div>
					</Item>
					<Item>
						<img src={Image3} alt="Atendimento íntegro e diferenciado" />
						<div><span>ATENDIMENTO ÍNTEGRO E DIFERENCIADO</span></div>
					</Item>
				</ItemsWrapper>
				<Subtitle>Acreditamos que ouvir bem é viver plenamente, e nosso compromisso é devolver a cada paciente a alegria de aproveitar todos os momentos da vida.</Subtitle>
				<Button onClick={() => navigate("/orcamento")}>Quero Receber Orçamento</Button>
			</Container>
		</SectionWrapper>
	);
}
