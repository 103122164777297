import { useContext, useState } from "react";

import { ApplicationContext } from "src/client/contexts/ApplicationContext";

import { Wrapper } from "../../../styles/admin";
import { WrapperConfiguration, Menu, MenuItem, Content } from "./styles";
import { Container } from "src/client/styles/layout";

import { ReactComponent as ArrowSimpleIcon } from "src/client/assets/icons/arrow-left.svg";
import { ReactComponent as UsersIcon } from "src/client/assets/icons/clients-light.svg";
import StaffsList from "./staffs/list";
import useRouter from "src/client/hooks/useRouter";

export default function AdminConfigsPage()
{
  const [section, setSection] = useState("staffs");
  const [mobileActive, setMobileActive] = useState(true);
	
  const { scroll, setScroll } = useContext(ApplicationContext);
	const router = useRouter();

  const changeSection = (newSection: string) => {
    setMobileActive(!mobileActive);
    setSection(newSection)
  }


	return <Wrapper $scroll={scroll} onScroll={(e: any) => setScroll(e.target.scrollTop)}>
		<Container>
			<h1>Configurações</h1>

      <WrapperConfiguration>
          <Menu mobileActive={mobileActive}>
            <div className="arrow">
              <ArrowSimpleIcon /> Configurações
            </div>
            <MenuItem $active={section === "staffs"} onClick={() => changeSection("staffs") }><UsersIcon /> Equipe</MenuItem>
          </Menu>
          <Content>
            {section === "staffs" && <StaffsList changeSection={changeSection} />}
          </Content>
        </WrapperConfiguration>
		</Container>
	</Wrapper>
}