import { Container } from "src/client/styles/layout";
import styled from "styled-components";
import professionals from "../data/professionais";

const Wrapper = styled.div`
    background: #F7F7F7;
    padding: 80px 0;

    h2 {
        font-size: 40px;
        font-weight: 800;
        margin: 0 0 80px;
        text-align: center;
    }
    
    @media (max-width: 576px) {
        padding: 60px 0;

        h2 {
            font-size: 2rem;
            margin: 0 0 60px;
        }
    }
`;

const List = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

    @media (max-width: 1400px) {
        gap: 30px;
    }

    @media (max-width: 1200px) {
        gap: 20px;
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 576px) {
        gap: 10px;
        margin: 0 -10px;
    }
`;

const Professional = styled.div`
    border-radius: 20px;
    background-color: #00bdad13;
    overflow: hidden;
    border: 1px solid rgb(0 0 0 / 10%);

    img {
        width: calc(100% + 8px);
        object-fit: cover;
        margin: -4px;
    }

    .info {
        padding: 30px;

        h3 {
            font-size: 1.4rem;
            font-weight: 800;
            color: var(--color-primary-light);
            margin: 0 0 20px;
        }

        p {
            font-size: 1.2rem;
            opacity: .9;
            color: #003f39;
        }
    }

    @media (max-width: 1200px) {
        .info {
            p {
                font-size: 1.1rem;
            }
        }
    }

    @media (max-width: 991px) {
        .info {
            
            h3 {
                font-size: 1.2rem;
            }

            p {
                font-size: 1rem;
            }
        }
    }

    @media (max-width: 576px) {
        border-radius: 12px;

        .info {
            padding: 15px;

            h3 {
                font-size: 1.1rem;
            }

            p {
                font-size: .8rem;
            }
        }
    }
`;

export default function Professionals()
{
    return <Wrapper>
        <Container>
            <h2>Nossa equipe</h2>
            <List>
                { professionals.map((item: any, i: number) => (
                    <Professional key={i}>
                        <img src={item.image} alt={item.name} />
                        <div className="info">
                            <h3>{item.name}</h3>
                            { item.paragraphs.map((paragraph: string, _i: number) => <p key={_i}>{paragraph}</p>) }
                        </div>
                    </Professional>
                )) }
            </List>
        </Container>
    </Wrapper>
}