import { useContext } from "react";
import Footer from "src/client/components/Layout/Footer";
import FormSection from "src/client/sections/institutional/Form";
import WhatsAppButton from "src/client/components/Layout/WhatsAppButton";
import { ApplicationContext } from "src/client/contexts/ApplicationContext";
import Testimonials from "src/client/sections/institutional/Testimonials";
import WhyChooseUsSection from "src/client/sections/institutional/WhyChooseUs";
import DepoimentosVideosList from "src/client/sections/institutional/depoimentos/DepoimentosVideosList";
import { WrapperInstitutional } from "src/client/styles/admin";

export default function DepoimentosInstitutionalPage() {

  const { scroll, setScroll } = useContext(ApplicationContext);

  return (
    <>
      <WhatsAppButton />

      <WrapperInstitutional $scroll={scroll} id="WrapperInstitutional" onScroll={(e: any) => setScroll(e.target.scrollTop)}>
        <DepoimentosVideosList />
        <Testimonials />
        <WhyChooseUsSection />
        <FormSection />
        <Footer />
      </WrapperInstitutional>
    </>
  )
}
