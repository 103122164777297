import { useContext } from "react";

import { ApplicationContext } from "../../contexts/ApplicationContext";
import { Wireframes } from "./styles";

import Windows from "../Windows";
import Loading from "./Loading";
import NewCompromisso from "./Compromissos/New";
import ClientDetails from "./Client";
import UpgradeDetails from "./Upgrade";

export type SectionTypes = "chat" | "newCompromisso" | "uploadDocument" | "previewDocument" | "loading" | "client";

type ConversationsTypes = {
  close: () => void;
}

export default function Conversations({ close }: ConversationsTypes) {
  
  const { conversations } = useContext(ApplicationContext);

  return <Windows status={conversations.visible} close={close} maxHeight={(!conversations.loading && conversations.section === "uploadDocument") ? "420px" : undefined}>
    <Wireframes>
      
      <NewCompromisso active={conversations.section === "newCompromisso" ? true : undefined} />
      <ClientDetails />
      <UpgradeDetails />
      <Loading loading={conversations.loading} />

    </Wireframes>
  </Windows>;
}