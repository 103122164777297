import PhoneInput from 'react-phone-input-2';

import styled from "styled-components";

import 'react-phone-input-2/lib/style.css'  

const InputSimplesContainer = styled.div<{ error?: boolean, textTransform?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  label {
    position: absolute;
    display: block;
    transition: 0.2s;
    font-size: 16px;
    top: 10px;
    left: 58px;
    font-weight: 800;
    color: rgba(0,0,0,.45);
    cursor: text;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    z-index: 2;
  }
  
  .react-tel-input {
    position: relative;
    margin: 0 0 10px;
    font-family: var(--font-family);

    color: var(--color);

    border: 2px solid ${props => props.error ? 'rgb(219, 127, 127)' : '#d1d1d1' };
    transition: .4s ease-in-out;

    border-radius: 16px;
    background: #FFF;

    > * {
      transition: .4s ease-in-out;
    }

    input {
      cursor: pointer;
      width: 100%;
      max-width: 100%;
      border: 0;
      color: ${props => props.error ? 'rgb(219, 127, 127)' : "var(--color)"};
      background: transparent;
      font-size: 22px;
      font-weight: 400;
      z-index: 2;
      position: relative;
      padding: 34px 6px 8px 56px;
      outline: none!important;
      height: inherit!important;
      max-height: 65px;
      
      &::placeholder {
        color: transparent;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
      }

      &:placeholder-shown ~ .special-label {
        cursor: text;
        top: 17px;
        font-size: 18px;
        font-weight: 800;
        opacity: .7;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        transition: .4s ease-in-out;
      }
      
      &:focus ~ .special-label {
        position: absolute;
        transition: 0.2s;
        top: 8px;
        font-size: 16px;
        font-weight: 800;
      }

      :required, .simple__field:invalid {
        box-shadow: none;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active {
        -webkit-box-shadow: none!important;
        background: transparent!important;
        outline: none!important;
        -webkit-text-fill-color: rgba(0,0,0,.8)!important;
        -webkit-transition: background-color 5000s ease-in-out 0s;
        transition: background-color 5000s ease-in-out 0s;
      }

      ${({ textTransform }) => textTransform && `
        text-transform: ${textTransform};
      `};

    }
  }

  .flag-dropdown {
    z-index: 100!important;
    background: transparent!important;
    border: none!important;
    
    &.open {
      width: 100%;
    }
    
    .selected-flag {
      margin: 2px;
      padding: 0 12px;
      width: 46px;
      height: 56px;
      border-radius: 4px!important;
      
      &:hover {
        background: #d3d3d3;
      }
    }

    .country-list  {
      width: 100%;
      border-radius: 10px;
      text-align: left;
    }
  }
`;

type ErrorTypes = {
  state?: boolean;
}

export const ErrorField = styled.div<ErrorTypes>`
  height: ${props => props.state ? 'auto' : '0'};
  padding: ${props => props.state ? '0 10px 10px' : '0'};
  margin: ${props => props.state ? '-6px 0 0' : '0'};
  border-radius: 10px;
  font-weight: 800;
  -webkit-transition: all .4s;
  color: #db7f7f;
  transition: all .4s;
  font-size: 14px;
  z-index: 2;
  
  display: flex;
  justify-content: flex-end;
`;

export type CountryPhoneTypes = {
  countryCode: string;
  dialCode: string;
  format: string;
  name: string;
}

type InputTypes = {
  value?: string;
  defaultValue?: string;
  error?: string;
  refs?: any;
  onChange?: ((phone: string, country: CountryPhoneTypes) => void) | any;
  className?: string;
  disabled?: boolean;
  textTransform?: "normal" | "capitalize" | "uppercase" | "lowercase";
}

const InputPhone = ({ value, textTransform, error, onChange, className, disabled = false }: InputTypes) => {

  function handleChange(phone: string, country: CountryPhoneTypes) {
    onChange(phone, country)
  }

  return (
    <InputSimplesContainer textTransform={textTransform} error={error ? true : undefined} className={className}>
      <label>Telefone *</label>
      <PhoneInput
        value={value}
        country={'br'}
        disabled={disabled ? true : undefined}
        onChange={(phone: string, country: CountryPhoneTypes, e, formmatedValue) => !disabled && handleChange(phone, country)}
      />
      <ErrorField state={error ? true : undefined}>{error}</ErrorField>
    </InputSimplesContainer>
  )
}

export default InputPhone;