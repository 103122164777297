import { useContext, useEffect, useState } from "react";
import moment from "moment";

import { ApplicationContext } from "../../../contexts/ApplicationContext";
import { Content } from "../styles";
import { Wrapper, LeadLogItem } from "./styles";

import { ReactComponent as WhatsAppIcon } from "../../../assets/icons/whatsapp.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/phone-light.svg";
import { ReactComponent as EmailIcon } from "../../../assets/icons/mail.svg";
import { getUserInitials } from "src/core/helpers/user";
import { ILead } from "src/core/interfaces/ILead";
import actions from "src/client/actions";
import { List } from "src/client/styles/admin";
import { ILeadLog } from "src/core/interfaces/ILeadLog";
import { phoneValidator } from "src/core/helpers/validations/lead";

export default function ClientDetails() {
	
	const { conversations, setLoading } = useContext(ApplicationContext);

	const [ lead, setLead ] = useState<ILead | null>(conversations.data);
	const [ logActive, setLogActive ] = useState<number | null>(null);
	
	async function getLeadLogs(data: ILead) {
		setLoading(true);

		try {
			const response = await actions.api.get(`/leads/${data?._id}/logs`);
			
			if (response) {
				data.logs = response.data;
			}
		} catch (error) {
			console.error("Erro ao buscar leads:", error);
		} finally {
			setLead(data);
			setLoading(false);
		}
	}
	
	useEffect(() => {
		if (!conversations.data) return;
		getLeadLogs(conversations.data);
	}, [conversations]);

	var phoneOnlyNumbers = conversations.data?.phone.replace(/\D/g, '');
	var phoneWithDDD = phoneOnlyNumbers?.startsWith("55") ? phoneOnlyNumbers : `55${phoneOnlyNumbers}`;
		
	if(conversations.section !== "client") return <></>;
	
	return <Content className="active">
		<Wrapper>
			<div className="profile">
				<div className="initials">{getUserInitials(lead?.name)}</div>
				<div className="info">
					<h4>{lead?.name}</h4>
					<span>Entrou em {moment(lead?.createdAt).format("DD/MM/YYYY")} às {moment(lead?.createdAt).format("HH:mm")}</span>
				</div>
			</div>
			
			<div className="data">
				<span>Origem:</span>
				<span><span>{(lead?.origin || "Desconhecido")?.toLowerCase()}</span></span>
			</div>
			
			<div className="data">
				<span>Telefone:</span>
				<span><span>{phoneOnlyNumbers || "-"}</span></span>
			</div>
			
			<div className="data">
				<span>E-mail:</span>
				<span><span>{lead?.email || "Não informado"}</span></span>
			</div>
			
			<div className="data">
				<span>Mensagem:</span>
				<span><span>{lead?.message || "Não informado"}</span></span>
			</div>
			
			<div className="actions">
				<div className="action" onClick={() => window.open(`https://wa.me/${phoneWithDDD}`)}>
					<WhatsAppIcon />
				</div>
				<div className="action phone" onClick={() => window.open(`tel:${phoneOnlyNumbers}`)}>
					<PhoneIcon />
				</div>
				{ lead?.email?.length ? 
					<div className="action email" onClick={() => window.open(`mailto:${lead?.email}`)}>
						<EmailIcon />
					</div>
				: <></> }
			</div>
						
			<div className="data logs">
				<h3>Atividades</h3>
				
				{ (!!lead?.logs && lead.logs.length > 0) ?
					<List className="list">
						{lead.logs.map((item: ILeadLog, i: number) => 
							<LeadLogItem key={i} className={item.type} onClick={() => setLogActive(i)} active={logActive === i}>
								<div className="title">
									<span className="moment">{moment(item.createdAt).format("DD/MM/yyyy - HH:mm:ss")}</span>
									<span className="type">{item.type}</span>
								</div>
								<div className="details">
									<span><strong>Requisição: </strong>{item.request}</span>
									<span><strong>Resposta: </strong>{item.response}</span>
								</div>
							</LeadLogItem> 
						)}
					</List>
				:
					<span>Não há registro de atividades</span> 
				}
			</div>
					
		</Wrapper>
	</Content>
}
	