"use client"

import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";

import { Button, CategoryTitle, Container } from "src/client/styles/layout";
import Input from "src/client/components/Inputs/official";
import { ApplicationContext } from "src/client/contexts/ApplicationContext";
import { Wrapper } from "src/client/styles/admin";

const Content = styled.form`
  max-width: 600px;
  margin: 0 auto;
`;

export default function DashboardAdminPerfil() {

  const [formState, setFormState] = useState<any>(null);
  const { user, scroll, setScroll } = useContext(ApplicationContext);

  useEffect(() => {
    if(!formState || !user) return;

    if((!formState?.name || formState?.name === user?.name) &&
      (!formState?.email || formState?.email === user?.email) &&
      (!formState?.phone || formState?.phone === user?.phone)) setFormState(null);
  }, [formState])

  async function handleSubmit(e: any) {
    e.preventDefault();

    if(!formState) return;

    // await actions.api.put("/users/update", formState)
    // .then(response => {
    //   if(response.data.error) return alert(response.data.error);

    //   // login({ ...user, ...formState });
    //   setFormState(null);

    //   alert("Dados atualizados com sucesso!");
    // })
  }

  return <Wrapper $scroll={scroll} onScroll={(e: any) => setScroll(e.target.scrollTop)}>
    <Container>
      <Content onSubmit={handleSubmit}>
        <CategoryTitle style={{ margin: "0 0 40px" }}>Meu perfil</CategoryTitle>

        <Input placeholder="Nome completo" defaultValue={user?.name} value={formState?.name} onChange={(e: any) => setFormState({ ...(formState || {}), name: e.target.value })} />
        <Input placeholder="E-mail" defaultValue={user?.email} value={formState?.email} onChange={(e: any) => setFormState({ ...(formState || {}), email: e.target.value })} />
        <Input placeholder="Telefone" defaultValue={user?.phone} value={formState?.phone} onChange={(e: any) => setFormState({ ...(formState || {}), phone: e.target.value })} />

        <Button style={{ margin: "20px 0 0 auto", opacity: formState ? 1 : .5, cursor: formState ? "pointer" : "not-allowed" }}>Salvar alterações</Button>
      </Content>
    </Container>
  </Wrapper>
}