declare global {
	interface Window {
		gtag: (...args: any[]) => void;
	}
}

const GA_TRACKING_ID = 'AW-827429632';

// Verifica se o gtag foi carregado
const isGtagLoaded = (): boolean => {
	return typeof window !== 'undefined' && typeof window.gtag === 'function';
};

// Função para registrar visualizações de página
const pageview = (url: string): void => {
	if (!isGtagLoaded()) return;
	
	window.gtag('config', GA_TRACKING_ID, {
		page_path: url,
	});
};

// Função para registrar eventos personalizados
const event = (action: "conversion", send_to: string, callback: any = null): void => {
	if (!isGtagLoaded()) return;
	
	window.gtag('event', 'conversion', {
		'send_to': send_to,
		'event_callback': callback
	});
};

export default {
	GA_TRACKING_ID,
	pageview,
	event
}