import { useEffect, useState } from 'react';

import { ItemEmpty } from '../styles';
import ManageStaff from './manage';

import { ReactComponent as PlusIcon } from 'src/client/assets/icons/saturnia-plus.svg';
import { ReactComponent as RemoveIcon } from 'src/client/assets/icons/saturnia-trash.svg';
import { ReactComponent as WaitIcon } from 'src/client/assets/icons/mail.svg';

import { Wrapper, Content, ListItems, Item } from "../styles/staffs";
import RoundedButton from 'src/client/components/buttons/rounded';
import { getUserInitials } from 'src/core/helpers/user';
import LoadingMoreItems from 'src/client/components/Loading/MoreItems';
import { CategoryTitle } from 'src/client/styles/layout';
import actions from 'src/client/actions';

export default function StaffsList({ }: any) {

  const [staffs, setStaffs] = useState<Array<any> | null>(null);
  const [staffQuests, setStaffQuests] = useState<Array<any> | null>(null);
  
  const [modal, setModal] = useState({ state: false }) as any;
  const [formState, setFormState] = useState({});

  const roles: Array<string> = [
    "Proprietário",
    "Administrador"
  ];

  async function loadList() {
    const { staffs: staffList, staffQuests }: Array<any> | any = await actions.api.get(`/staffs`).then(response => response.data);
    
    setStaffs(staffList)
    setStaffQuests(staffQuests)
  }

  useEffect(() => {
    if(!staffs || !staffQuests) loadList();
  }, [staffs, staffQuests])

  function handleEdit(staff: any) {
    setFormState({ });
    setModal({ state: true, type: 'edit' });
  }

  function confirmation(textConfirm: string, cb: () => void) {
    if(window.confirm(textConfirm)) cb();
  }
  
  function handleRemove(staff: any) {
    confirmation("Deseja excluir esse colaborador?", async () => {
      await actions.api.delete(`/staffs?type=staff&id=${staff?._id}`).then(response => {
        alert("Colaborador removido com sucesso!");
        loadList();
      })
    })
  }

  function handleRemoveQuest(staff: any) {
    confirmation("Deseja excluir esse convite?", async () => {
      await actions.api.delete(`/staffs?type=invite&id=${staff?._id}`).then(response => {
        alert("Convite cancelado com sucesso!");
        loadList();
      })
    })
  }

  const onPrepareToAdd = () => {
    setModal({ state: true, type: 'create' });
    setFormState({ email: "" });
  }
  
  return (
    <>
      <ManageStaff roles={roles} modal={modal} formState={formState} setFormState={setFormState} close={() => setModal(false)} refreshList={() => loadList()} />
      
      <Wrapper>
        <Content>

          <div style={{ display: "grid", gridTemplateColumns: "1fr auto", gap: 30 }}>
            <CategoryTitle style={{ margin: 0 }}>Equipe</CategoryTitle>
            
            <RoundedButton
              onClick={() => onPrepareToAdd()}
              text="Convidar"
              icon={<PlusIcon />}
              />
          </div>

          { staffs ? 
            staffs.length > 0 ? <>
              <ListItems $active={true}>
                {staffs?.map((staff: any, i: number) => {
                  return (
                    <Item key={i}>
                      <div className="profile-image">
                        {getUserInitials(staff?.name)}
                      </div>
                      <div className="info">
                        <h3>{staff?.name}</h3>
                        <span>{staff?.email}</span>
                      </div>
                      <div className="actions">
                        <div></div>
                        {/* <div className="action" onClick={() => handleEdit(staff)}><EditIcon /></div> */}
                        <div className="action" onClick={() => handleRemove(staff)}><RemoveIcon /></div>
                      </div>
                    </Item>
                  )
                })}
              </ListItems>
            </>
            :
            <ItemEmpty>Você ainda não adicionou colaboradores.</ItemEmpty>
          : <LoadingMoreItems /> }

          { staffQuests && staffQuests.length > 0 ? <>
              <CategoryTitle>Convites pendentes</CategoryTitle>
              
              <ListItems $active={true}>
                {staffQuests?.map((staff: any, i: number) => {
                  return (
                    <Item key={i}>
                      <div className="profile-image">
                        <WaitIcon />  
                      </div>
                      <div className="info">
                        <h3>{staff?.email}</h3>
                        <span>{roles[1]} <span className="pending">Pendente</span></span>
                      </div>
                      <div className="actions">
                        <div></div>
                        <div className="action" onClick={() => handleRemoveQuest(staff)}><RemoveIcon /></div>
                      </div>
                    </Item>
                  )
                })}
              </ListItems>
            </>
            :
            <></> }

        </Content>
      </Wrapper>
    </>
  )
}