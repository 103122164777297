import { fakeLoad } from "src/client/styles/globals";
import styled from "styled-components";

export const Wrapper = styled.div<{ $prepared: boolean, $scroll: number, $pagetype: "Institutional" | "Dashboard" }>`
	display: flex;
	align-items: center;

	/* box-shadow: ${({ $scroll }) => $scroll > 0 ? `0 0 80px rgba(0,0,0,.04);` : `0 0 80px rgba(0,0,0,0);`}; */
	position: relative;
	z-index: 999;
	background-color: var(--color-white);

	${({ $pagetype }) => $pagetype === "Dashboard" && `
		border-bottom: 1px solid var(--color-border-secondary);
	`};

	* {
		user-select: none;
		-o-user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
	}

	@media (max-width: 991px) {
		&.hideInMobile { display: none; }
	}

	${({ $prepared }) => !$prepared && fakeLoad};
`;

export const Content = styled.div<{ $prepared: boolean, $scroll: number,  $active?: boolean, $pagetype: "Institutional" | "Dashboard" }>`
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	position: relative;
	transition: .2s ease-in-out;

	${({ $scroll }) => $scroll > 70 ? `
		height: calc(var(--header-size) - 20px);
		@media (max-width: 991px) { height: 80px; }
	` : `
		height: calc(var(--header-size) + 0px);
		@media (max-width: 991px) { height: 80px; }
	`};

	.logo {
		max-height: 100%;
		max-width: 180px;
		cursor: pointer;
		height: 100%;
	}

	.menu {
		margin: 0 auto;
		display: flex;
		align-items: center;
		
		.button {
			margin: 0 5px;
			padding: 10px 15px;
			text-decoration: none;
			font-size: 14px;
			font-weight: 800;
			background: transparent;
			cursor: pointer;
			text-transform: uppercase;
			
			display: flex;
			align-items: center;
			
			&.mobile { display: none; }
			border: 1px solid transparent;
			border-radius: 100px;
			
			transition: 200ms ease-in-out, border-color 0s, font-size 0s, border-radius 0s, padding 0s;
			
			&:hover {
				color: rgba(0,0,0,1);
				background: var(--color-background);
				border-color: var(--color-border);
			}
			
			.icon {
				position: relative;

				display: flex;
				justify-content: center;
				align-items: center;
				
				svg {
					--size: 19px;
					min-width: var(--size);
					max-width: var(--size);
					max-height: var(--size);
					fill: var(--color);
					margin-right: 10px;
					
					path {
						fill: var(--color);
					}
				}
				
				.new {
					--size: 10px;
					width: var(--size);
					height: var(--size);
					border-radius: var(--size);
					background-color: var(--color-primary);
					position: absolute;
					top: -4px;
					right: -4px;
				}
			}
			
			span {
				white-space: nowrap;
				padding: 0 2px;
			}
			
			&.active {
				font-weight: 800;
				color: var(--color-primary);
				position: relative;
				
				.icon {
					svg {
						fill: var(--color-primary);
						
						path {
							fill: var(--color-primary);
						}
					}
					
					.new {
						opacity: 0;
					}
				}
				
				${({ $pagetype }) => $pagetype === "Dashboard" && `
					background: var(--color-hover);
    				border-color: var(--color-border);
				`};
						
				${({ $pagetype }) => $pagetype === "Institutional" && `
					&::before {
						content: "";
						width: 18px;
						height: 4px;
						border-radius: 6px;
						background: var(--color-primary);
						
						position: absolute;
						bottom: -3px;
						left: 0;
						right: 0;
						margin: auto;
					}
				`};
			}
		}

		.just-icon {
			span {
				display: none;
			}

			.icon svg {
				margin: 0;
			}
		}
	}

	.actions {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.initials {
		--picture-size: 45px;
		height: var(--picture-size);
		min-width: var(--picture-size);
		max-width: var(--picture-size);
		border-radius: 100%;
		background-color: var(--color-primary);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		color: white;
		font-size: 18px;
		font-weight: 800;
		display: flex;
		justify-content: center;
		align-items: center;

		@media (max-width: 576px) {
			--picture-size: 45px;
		}
	}

	.toogleMobile { display: none; }

	.goToMyAccount {
		line-height: 0;
		padding: 10px 24px;

		svg {
			height: 22px;
			min-width: 22px;
			max-width: 22px;
			margin: 0 14px 0 0;
		}
	}

	@media (max-width: 1280px) {
		grid-template-columns: 1fr auto auto;
	}

	@media (max-width: 1080px) {
		.logo {
			max-width: 180px;
		}
	}

	@media (max-width: 1200px) {
		${({ $pagetype, $active }) => $pagetype === "Institutional" ? `
			.menu {
				background: var(--color-white);
				border-top: 1px solid var(--color-border);
			
				position: absolute;
				overflow: hidden;
				width: ${$active ? 'calc(100% + 80px)' : '0'};
				right: -40px;
			
				top: var(--header-size);
				height: calc(100vh - var(--header-size));
				z-index: 999;
			
				flex-direction: column;
			
				.button {
					padding: 30px 50px;
					width: 100%;
					border: none;
					background: transparent;
					border-bottom: 1px solid var(--color-border);
					border-radius: 0;
				
					font-size: 18px;
				
					&.mobile {
						display: flex;
					}

					&.goToMyAccount button {
						padding: 14px 20px;

						svg {
							margin: 0 14px 0 0;
						}
					}
				
					svg {
						--size: 24px;
						margin-right: 20px;
						margin-bottom: 2px;
						margin-top: -2px;
					}
				
					&.active {
				
						&::before {
						display: none;
						}
					}
				
					&:hover {
						background: rgba(0,0,0,.05);
					}
				}
			}
			
			.goToMyAccount { margin: 0 20px; }
			
			.toogleMobile {
				display: flex;
				flex-direction: column;
			
				--size: 60px;
				width: var(--size);
				height: var(--size);
				margin: auto;
				border-radius: 8px;
				padding: 10px;
				cursor: pointer;
			
				margin-right: -20px;
			
				position: relative;
			
				div {
				width: 100%;
				height: 5px;
				border-radius: 10px;
				background: var(--color);
				margin: 4px 0;
			
				transition: 100ms ease-in-out, width 0s, height 0s;
				
				${$active && `
					&:nth-of-type(2) {
					opacity: 0;
					}
			
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					margin: auto;
					width: 70%;
			
					transform: rotate(45deg);
			
					&:nth-of-type(3) {
					transform: rotate(-45deg);
					}
				`};
				}
			
				transition: 200ms ease-in-out;
			
				&:hover {
				background: var(--color-border);
				}
			}
		` : `
			.menu {
				background: var(--color-white);
				position: fixed;
				width: 100vw;
				right: 0;
				bottom: 0;
			
				&::-webkit-scrollbar { display: none!important; }
			
				.button {
				height: 70px;
				width: 100%;
				border: none;
				background: transparent;
				border-top: 3px solid var(--color-border);
				border-radius: 0;
				margin: 0;
			
				font-size: 18px;
			
				span {
					display: none;
				}
			
				.icon {
					margin: auto;
					
					svg {
					--size: 24px;
					}
				}
			
				&.active {
			
					&::before {
					display: none;
					}
				}
			
				&:hover {
					background: rgba(0,0,0,.05);
				}
				}
			}
		`}
	}

	@media (max-width: 680px) {
		.goToMyAccount { display: none; }
	}

	@media (max-width: 576px) {
		
		${({ $pagetype, $active }) => $pagetype === "Institutional" ? `
		.menu {
			width: ${$active ? 'calc(100% + 40px)' : '0'};
			right: -20px;
		
			.button {
			padding: 25px 30px;
			}
		}
		
		.toogleMobile {
			margin-right: -6px;
			--size: 56px;
		}
		` : `
		.menu {}
		`};
	}

	@media (max-width: 420px) {
		.logo {
			max-width: 85%;
		}
	}


	${({ $prepared }) => !$prepared && `
		.menu .button {
		${fakeLoad};
		}

		.actions .image {
			${fakeLoad};

			.initials {
				color: transparent;
			}
		}

  `};
`;