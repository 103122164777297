import { styled } from "styled-components"

type ComponentProps = {
  initialDate: Date,
  cb: (date: Date | null) => void;
}

const Input = styled.input`
  width: 100%;
  padding: 12px 20px;
  font-size: 18px;
  cursor: pointer;
  
  border: 2px solid rgba(0,0,0,.2);
  border-radius: 8px;

  position: relative;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background: transparent;

    position: absolute;
    top: 0;
    right: 0;
  }

`;

export default function InputDate({ initialDate, cb }: ComponentProps) {

  return <Input
    type="datetime-local"
    defaultValue={initialDate?.toISOString().slice(0, 16)}
    onClick={(ev) => ev.currentTarget.showPicker()}
    onChange={(ev: any) => cb(ev.target.value)}
    />
}