import styled from "styled-components";

export const FooterWrapper = styled.footer`
	background-color: #9ad6ce;

	.container {
		display: grid;
		grid-template-areas: "UNIT UNIT UNIT" "INFO LINKS LINKS";
	}

	@media (max-width: 768px) {
		.container {
			display: grid;
			grid-template-areas: "UNIT" "LINKS" "INFO";
		}
	}

	a, button {
		color: #006356!important;
	}
`;

export const FooterContent = styled.div`
  	padding: 80px 0;

	.logo {
		grid-area: LOGO;
		margin: 40px auto;
		max-width: 300px;
		display: none;
	}

	@media (max-width: 768px) {
		padding: 30px 0 0;
	}
`;

export const FooterSection = styled.div`
	flex: 1;
	margin: 20px;

	h4 {
		font-size: 1.3rem;
		margin-bottom: 15px;
		color: #006356;
		font-weight: 800;
	}

	p, a {
		font-size: 1rem;
		color: #006356;
		margin: 5px 0;
	}

	a {
		text-decoration: none;
		display: block;
	}

	&.info-section {
		grid-area: INFO;

		p {
			font-size: 1rem;
			font-weight: 700;
		}

		.contact {
			display: flex;
			margin: 20px 0;

			button, a {
				--size: 50px;
				min-width: var(--size);
				max-width: var(--size);
				height: var(--size);
				border: none;

				cursor: pointer;

				display: flex;
				align-items: center;
				justify-content: center;
				background-color: transparent;
				border-radius: 50%;
				margin-right: 10px;

				padding: 10px;

				background: rgb(10 129 114 / 10%);

				svg {
					width: 22px;
					height: 22px;

					path {
						fill: #004f1d;
					}
				}

				&.stroke {
					path {
						stroke: #004f1d;
						fill: transparent;
					}
				}

				&:hover {
					background: rgb(10 129 114 / 30%);
				}
			}
		}
	}

	&.links-section {
		grid-area: LINKS;

		a {
			font-size: 1.2rem;
			font-weight: 700;
			transition: .2s ease-in-out;
			padding: 6px 0;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&.socials-section {
		grid-area: SOCIALS;

		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	
`;

export const Units = styled.div`
	grid-area: UNIT;

	display: grid;
	grid-template-columns: 1.5fr 1.4fr 1.4fr 1.4fr;
	gap: 30px;

	border-bottom: 1px solid rgb(10 129 114 / 25%);
	padding-bottom: 40px;
	margin-bottom: 40px;
	
	@media (max-width: 768px) {
		flex-direction: column;
	}

	.unit {
		flex: 1;
		min-width: 200px;

		display: flex;
		flex-direction: column;
		justify-content: space-between;

		p {
			font-size: 1.05rem;
			font-weight: 700;
			color: rgba(0,0,0,.8);
		}

		.title {
			h4 {
				color: #006356;
				font-size: 1.3rem;
				font-weight: 800;
			}

			h5 {
				color: #006356;
				font-size: 1rem;
				font-weight: 700;
				margin-bottom: 10px;
			}
		}

		.location {
			margin: 10px 0;
			height: 100%;
		}

		.phone {
			margin: 10px 0 0;

			button {
				font-size: 1.2rem;
				font-weight: 700;
				
				border: 2px solid rgb(10 129 114 / 40%);
				background: transparent;
				margin: 0 0 5px;
				padding: 10px 20px;
				border-radius: 10px;
				width: 100%;

				display: flex;
				align-items: center;

				color: #004f1d;
				cursor: pointer;

				svg {
					width: 22px;
					margin-right: 10px;
				}

				&:first-child {
					svg path {
						stroke: #004f1d;
					}
				}

				&:last-child {
					svg path {
						fill: #004f1d;
					}
				}

				&:hover {
					background: rgb(10 129 114 / 10%);
				}
			}
		}
	}

	@media (max-width: 1400px) {
		.unit {
			.title {
				h4 {
					font-size: 1.2rem;
				}

				h5 {
					font-size: 1rem;
				}
			}
		}
	}

	@media (max-width: 1100px) {
		grid-template-columns: 1fr 1fr;
		gap: 60px 40px;
	}

	@media (max-width: 768px) {
		grid-template-columns: 1fr;
		gap: 50px;
		padding: 20px 20px 60px;
	}
`;

export const FooterBottom = styled.div`
	background-color: black;
	color: #fff;
	text-align: center;
	font-size: 0.9rem;
	position: relative;
	padding: 30px 0;

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	p {
		margin: 5px 0;
		display: flex;
		font-size: 1.2rem;
	}

	.logo-saturnia {
		height: 24px;
		min-height: 24px;
		margin-left: 10px;
	}

	a {
		color: #fff;
		text-decoration: none;
		font-weight: bold;
	}

	@media (max-width: 1100px) {
		p {
			font-size: 1rem;
			font-weight: 700;
		}

		.logo-saturnia {
			height: 20px;
		}
	}

	@media (max-width: 1100px) {
		min-height: 150px;
		
		.container {
			flex-direction: column;
		}
	}

	@media (max-width: 768px) {
		min-height: 150px;
		padding: 20px 20px 100px;
		
		p {
			font-size: .9rem;
			margin-bottom: 20px;
			max-width: 280px;

			&:last-child {
				display: flex;
				flex-direction: column;
			}

			a {
				margin-top: 10px;
			}
		}
	}
`;