import { styled } from "styled-components";

type ComponentTypes = {
  className?: string;
  background?: string;
  loading?: {
    text: string
  } | null | any
}

const Wrapper = styled.div<{ $background?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  background: ${({ $background }) => $background || "#FFF"};
  width: 100%;
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .loading {
      width: 60px;
      height: 60px;
  
      border-radius: 100%;
  
      border: 10px solid var(--color-primary-light);
      border-left-color: transparent;
      animation: 800ms AnimationLooping infinite linear;
  
      @keyframes AnimationLooping {
        0% {
          rotate: calc(0);
        }
        100% {
          rotate: calc(360deg);
        }
      }
    }

    .text {
      font-size: 18px;
      margin: 30px 0 -30px;
      opacity: .7;
    }
  }

`;

export default function Loading({ className, background, loading }: ComponentTypes) {
  return <Wrapper $background={background} className={`${className || ""}${loading ? " active" : ""}`}>
    <div className="content">
      <div className="loading"></div>
      <div className="text">{loading?.text || "Carregando..."}</div>
    </div>
  </Wrapper>
}