import { useState, useEffect, useRef } from "react";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";

import { Button } from "src/client/styles/layout";
import { ReactComponent as ArrowIcon } from "src/client/assets/icons/arrow-simple.svg";
import { ReactComponent as FiveStars } from "src/client/assets/illustrations/five-stars.svg";
import testimonials from "../data/testimonials.json";

interface TestimonialBoxProps {
  textLength: number;
}

const TestimonialsSection = styled.section`
  padding: 100px 0;
  text-align: center;
  position: relative;
  overflow: hidden;

  @media (max-width: 576px) {
    padding: 60px 0;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;

  span {
    color: var(--color-primary-light);
    font-weight: 800;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 576px) {
    font-size: 1.6rem;
  }
`;

const TestimonialWrapper = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 100%;
`;

const SliderWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  padding: 60px 0 40px;
  cursor: grab;
`;

const TestimonialBox = styled.div<TestimonialBoxProps>`
  flex: 0 0 calc(33.33% - 40px);
  max-width: calc(33.33% - 40px);
  margin: 0 20px;
  background-color: #0a817210;
  border-radius: 16px;
  padding: 30px;
  box-shadow: 0 0 20px #0a817240;
  border: 2px solid var(--color-primary-light);
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  user-select: none;

  h4 {
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--color-primary-light);
  }

  svg.stars {
    margin: 20px auto;
    display: block;
  }

  p {
    font-size: ${(props) =>
      props.textLength > 350 ? ".8rem" : props.textLength > 150 ? "1rem" : "1.6rem"};
    color: #555;
    line-height: 1.5;
    opacity: 0.9;
    padding: 10px;
    transition: font-size 0.3s ease;
  }

  @media (max-width: 1024px) {
    flex: 0 0 calc(50% - 40px);
    max-width: calc(50% - 40px);
  }

  @media (max-width: 768px) {
    flex: 0 0 calc(100% - 40px);
    max-width: calc(100% - 40px);
  }
`;

const ArrowButton = styled.button<{ hidden?: boolean }>`
  background-color: var(--color-primary-light);
  border: none;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  z-index: 10;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &.prev {
    left: 10px;

    svg {
      transform: scaleX(-1);
    }
  }

  &.next {
    right: 10px;
  }

  svg {
    fill: white;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: var(--color-primary);
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
  padding: 0 20px;

  button:last-of-type {
    background-color: transparent;
    border: 2px solid var(--color-primary-light);
    color: var(--color-primary-light);
    box-shadow: none !important;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    Button {
      font-size: 1rem;
      padding: 15px 20px;
    }
  }

  @media (max-width: 480px) {
    Button {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export default function Testimonials() {
  const navigate = useNavigate();
  const [testimonialIndex, setTestimonialIndex] = useState(0);
  const [testimonialsPerPage, setTestimonialsPerPage] = useState(3);
  const [dragStart, setDragStart] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  useEffect(() => {
    const updateTestimonialsPerPage = () => {
      if (window.innerWidth < 768) {
        setTestimonialsPerPage(1);
      } else if (window.innerWidth < 1024) {
        setTestimonialsPerPage(2);
      } else {
        setTestimonialsPerPage(3);
      }
    };

    updateTestimonialsPerPage();
    window.addEventListener("resize", updateTestimonialsPerPage);

    return () => {
      window.removeEventListener("resize", updateTestimonialsPerPage);
    };
  }, []);

  const maxIndex = testimonials.length - testimonialsPerPage;

  function nextTestimonial() {
    setTestimonialIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;
      return nextIndex > maxIndex ? maxIndex : nextIndex;
    });
  }

  function prevTestimonial() {
    setTestimonialIndex((prevIndex) => {
      const nextIndex = prevIndex - 1;
      return nextIndex < 0 ? 0 : nextIndex;
    });
  }

  function handleDragStart(event: React.MouseEvent | React.TouchEvent) {
    const clientX = "touches" in event ? event.touches[0].clientX : event.clientX;
    setDragStart(clientX);
    setIsDragging(true);
  }

  function handleDragEnd(event: React.MouseEvent | React.TouchEvent) {
    if (!isDragging || dragStart === null) return;

    const clientX = "changedTouches" in event ? event.changedTouches[0].clientX : event.clientX;
    const dragDistance = dragStart - clientX;

    const threshold = 100; // Minimum drag distance to change slide
    if (dragDistance > threshold) {
      nextTestimonial();
    } else if (dragDistance < -threshold) {
      prevTestimonial();
    }

    setIsDragging(false);
    setDragStart(null);
  }

  return (
    <TestimonialsSection>
      <Title>
        Confira os depoimentos <br /> dos <span>nossos pacientes:</span>
      </Title>
      <TestimonialWrapper>
        <ArrowButton onClick={prevTestimonial} className="prev" hidden={testimonialIndex === 0}>
          <ArrowIcon />
        </ArrowButton>
        <SliderWrapper
          style={{ transform: `translateX(-${testimonialIndex * (100 / testimonialsPerPage)}%)` }}
          onMouseDown={handleDragStart}
          onMouseUp={handleDragEnd}
          onMouseLeave={handleDragEnd}
          onTouchStart={handleDragStart}
          onTouchEnd={handleDragEnd}
        >
          {testimonials.map((testimonial, index) => (
            <TestimonialBox
              key={index}
              textLength={testimonial.text.length}
            >
              <p>{testimonial.text}</p>
              <FiveStars className="stars" />
              <h4>{testimonial.name}</h4>
            </TestimonialBox>
          ))}
        </SliderWrapper>
        <ArrowButton
          onClick={nextTestimonial}
          className="next"
          hidden={testimonialIndex >= maxIndex}
        >
          <ArrowIcon />
        </ArrowButton>
      </TestimonialWrapper>
      <ButtonsWrapper>
        <Button onClick={() => navigate("/orcamento")}>Quero Receber Orçamento</Button>
        <Button onClick={() => navigate("/depoimentos")}>Ver Mais Depoimentos</Button>
      </ButtonsWrapper>
    </TestimonialsSection>
  );
}
