import { styled } from "styled-components";

export const Wireframes = styled.div`
  display: flex;

  > div {
    width: 100%;
    display: none;
    opacity: 0;
    overflow: hidden;
    
    *, p, ::after, ::before {
      opacity: 0;
    }
  }
  
  > div.active {
    display: flex;
    opacity: 1;
    transition: 100ms ease-in-out 100ms;
    overflow: initial;
    
    *, p, ::after, ::before {
      opacity: 1;
      transition: opacity 100ms ease-in-out 200ms;
    }
  }
`;

export const Content = styled.div`
  min-height: 100%;
  
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 23px;
  }

  h5 {
    font-size: 19px;
    margin: 0 0 10px;
  }

  p {
    font-size: 16px;
    margin: 8px 0 0;
    opacity: .8;
  }
`;

export const ContentItem = styled.div`
  margin: 30px 0 0;
`;