import styled from "styled-components"

const Wrapper = styled.button<{ type?: any, margin: string, text: boolean, onLoading: boolean }>`
  padding: ${({ text }) => text ? '14px 24px 14px 20px' : '14px'};
  border-radius: 50px;

  font-family: "Poppins", sans-serif!important;
  
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
  color: white;

  margin: ${({ margin }) => margin};

  font-size: 15px;
  font-weight: 700;

  cursor: pointer;
  transition: .2s ease-in-out;
  overflow: hidden;
  white-space: nowrap;

  display: flex;
  align-items: center;

  svg {
    height: 12px;
    max-width: 12px;
    min-width: 12px;
    fill: white;
    margin: auto;
    margin-right: ${({ text }) => text ? '14px' : 'auto'};
  }

  :hover {
    opacity: 1;
  }

  &.disabled {
    opacity: .5!important;
  }
    
  &.centered {
    margin: auto;
  }

  &.maxWidth {
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  &.min {
    padding: ${({ text }) => text ? '10px 16px' : '10px'};
    height: 37px;
    min-width: 37px;

    svg {
      min-width: 16px!important;
      max-width: 16px!important;
      height: 16px!important;
    }

    @media (max-width: 768px) {
      height: 50px;
      min-width: 50px;
      max-width: 50px;
      padding: 16px;

      span {
        display: none;
      }

      svg {
        margin: 0 !important;
        min-width: 100%!important;
        max-width: 100%!important;
        height: 100%!important;
      }
    }
  }

  &.close {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    > div {
      width: 45%;
      height: 3px;
      border-radius: 10px;
      background: #7c7c7c;
      margin: 1px 0;

      transition: .4s ease-in-out;

      position: absolute;
      
      :nth-child(1) {
        transform: rotate(45deg);
      }
      :nth-child(2) {
        display: none;
      }
      :nth-child(3) {
        transform: rotate(-45deg);
      }
    }

    @media (max-width: 768px) {
      > div {
        width: 40%;
      }
    }
  }

  ${({ theme, type, text, onLoading }) => type === "app" && `
    min-width: 60px;
    width: 100%;
    max-width: 100%;
    
    text-transform: uppercase;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    
    min-height: 60px;
    max-height: 60px;

    position: relative;
    
    svg {
      fill: white!important;
    }
    
    ${onLoading && `
      max-width: 60px;
      color: transparent;
      background: transparent;
      
      transition: color 0s, max-width .6s ease-in-out, background .7s ease-in-out .3s;
      
      :before {
        opacity: ${onLoading ? '1' : '0'};
        transition: .2s ease-in-out 2s;
        content: "";
        background: transparent;
        margin: auto;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        border: 7px solid var(--color-primary);
        border-radius: 100%;

        border-left: 7px solid transparent;

        animation: 1s AnimationButtonAppLoading infinite cubic-bezier(.17,1.1,.78,.21);

        @keyframes AnimationButtonAppLoading {
          0% {
            transform: rotate(0);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    `};
  `};

  ${({ theme, type }) => type === "success" && `
    background: ${theme?.colors?.orange?.one || 'rgba(0,0,0,.3)'};
    color: white;
    
    svg {
      fill: white!important;
    }
  `};

  ${({ type }) => type === "white" && `
    background: white;
    
    svg {
      fill: var(--color)!important;
    }
  `};
  
  ${({ theme, type }) => type === "gray" && `
    background: #898989;
    color: white;
    
    svg {
      fill: white!important;
    }
  `};
  
  ${({ theme, type }) => type === "purple" && `
    background: #8a4bff;
    color: white;
    
    svg {
      fill: white!important;
    }
  `};

  ${({ theme, type }) => type === "green" && `
    background: #8de585;
    color: #1d7616;
    
    svg {
      fill: #1d7616!important;
    }
  `};

  ${({ theme, type }) => type === "actions" && `
    background: #e3e3e3;
    color: #7c7c7c;
    
    svg {
      fill: #7c7c7c!important;
    }

    opacity: .6;

    :hover {
      opacity: 1;
    }
  `};

  ${({ theme, type }) => type === "success-with-border" && `
    background: transparent;
    border: 3px solid;
    color: #f29300;
    border-color: #f29300;
    background: #f2a630;
    color: white;
    font-weight: 800;
    font-size: 16px;
    
    svg {
      fill: #7c7c7c!important;
    }

    opacity: 1;

    :hover {
      background: #f29300;
    }

    @media (max-width: 576px) {
      font-size: 15px;
    }
  `};
`;

type ButtonTypes = {
  text: string;
  onClick: any;
  type?: "app" | "success" | "white" | "gray" | "purple" | "actions" | "green" | "success-with-border";
  icon?: any;
  margin?: string;
  className?: string;
  style?: any;
  onLoading?: boolean;
}

export default function RoundedButton({ style, type, text, icon, onClick, margin = "0", className, onLoading = false }: ButtonTypes) {
  return <Wrapper onLoading={onLoading} type={type} onClick={onClick} style={style} margin={margin} className={className} text={text?.length > 0}>
    {className?.includes("close") ? <>
      <div></div>
      <div></div>
      <div></div>
    </> : icon} <span>{text}</span>
  </Wrapper>
}