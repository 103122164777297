import styled from "styled-components";

export const Wrapper = styled.div`
	h2 {
		font-size: 2rem;
	}

	p {
		font-size: 1rem;
	}

	Button {
		margin: 20px 0 0;
		border-radius: 10px;
	}
`;