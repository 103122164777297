import { useState, useEffect } from 'react';

export const useInactivityTimer = (timeout = 180000) => { // 180000ms = 3 minutos
    const [isActive, setIsActive] = useState(true);

    useEffect(() => {

        // Função para reiniciar o timer
        const handleActivity = () => {
            setIsActive(true);
            clearTimeout((window as any).inactivityTimer);
            (window as any).inactivityTimer = setTimeout(() => setIsActive(false), timeout);
        };

        // Definindo eventos para detectar atividade do usuário
        document.addEventListener('mousemove', handleActivity);
        document.addEventListener('keydown', handleActivity);
        document.addEventListener('scroll', handleActivity);
        document.addEventListener('click', handleActivity);

        // Inicializando o timer na montagem
        handleActivity();

        return () => {
            // Limpeza ao desmontar o componente
            clearTimeout((window as any).inactivityTimer);
            document.removeEventListener('mousemove', handleActivity);
            document.removeEventListener('keydown', handleActivity);
            document.removeEventListener('scroll', handleActivity);
            document.removeEventListener('click', handleActivity);
        };
    }, [timeout]); // Re-executa quando o timeout muda

    return isActive;
};