import ImageMore from "src/client/assets/images/products/more.png";
import ImageIIC from "src/client/assets/images/products/iic.png";
import ImageRecarregavel from "src/client/assets/images/products/recarregavel.png";
import ImageGENESIS from "src/client/assets/images/products/genesis.png";
import ImageCIC from "src/client/assets/images/products/cic.png";
import ImageCROS from "src/client/assets/images/products/cros.png";

import AparelhoPreto from "src/client/assets/images/products/slide/01.png";
import AparelhoBege from "src/client/assets/images/products/slide/02.png";
import AparelhoTerracota from "src/client/assets/images/products/slide/03.png";
import AparelhoMarrom from "src/client/assets/images/products/slide/04.png";
import AparelhoCinza from "src/client/assets/images/products/slide/05.png";
import AparelhoCinzaEscuro from "src/client/assets/images/products/slide/06.png";
import AparelhoGrafite from "src/client/assets/images/products/slide/07.png";
import AparelhoAzulRoyal from "src/client/assets/images/products/slide/08.png";

export const aparelhosAuditivos = [
    {
        name: "MORE",
        image: ImageMore,
        paragraphs: [
            "Com Inteligência Artificial, adapta-se ao ambiente sonoro, oferecendo melhor desempenho auditivo em situações ruidosas e silenciosas."
        ]
    },
    {
        name: "INVISÍVEL NO CANAL (IIC)",
        image: ImageIIC,
        paragraphs: [
            "Esse aparelho auditivo é feito sob medida, ele fica discretamente dentro do canal auditivo, por isso parece quase invisível aos olhos."
        ]
    },
    {
        name: "Recarregável",
        image: ImageRecarregavel,
        paragraphs: [
            "Com bateria recarregável, o aparelho elimina a necessidade de trocar baterias e permite carregamento portátil sem tomada, com design resistente à poeira e umidade."
        ]
    },
    {
        name: "GÊNESIS AI",
        image: ImageGENESIS,
        paragraphs: [
            "Monitore sua saúde via smartphone, transmita áudio diretamente aos aparelhos auditivos e faça ajustes remotos com sua fonoaudióloga."
        ]
    },
    {
        name: "MICRO CANAL (CIC)",
        image: ImageCIC,
        paragraphs: [
            "Discreto e de alto desempenho, o aparelho intracanal é personalizado para oferecer máximo conforto e adaptação ao ouvido."
        ]
    },
    {
        name: "CROS",
        image: ImageCROS,
        paragraphs: [
            "O CROS é indicado para pessoas com perda auditiva unilateral total, transmitindo sem fio o som da orelha com perda para o aparelho na orelha saudável, permitindo ouvir sons do lado afetado."
        ]
    }
];

export const aparelhoColorOptions = [
    {
        name: "Preto",
        hex: "#2B2E31",
        image: AparelhoPreto
    },
    {
        name: "Bege",
        hex: "#d5c8bd",
        image: AparelhoBege
    },
    {
        name: "Terracota",
        hex: "#a48f7f",
        image: AparelhoTerracota
    },
    {
        name: "Marrom",
        hex: "#6B5239",
        image: AparelhoMarrom
    },
    {
        name: "Cinza",
        hex: "#DEDEDE",
        image: AparelhoCinza
    },
    {
        name: "Cinza Escuro",
        hex: "#BBBBBB",
        image: AparelhoCinzaEscuro
    },
    {
        name: "Grafite",
        hex: "#666666",
        image: AparelhoGrafite
    },
    {
        name: "Azul Royal",
        hex: "#223F62",
        image: AparelhoAzulRoyal
    },
]