import { useEffect, useRef, useState } from 'react';

import { InputSimplesContainer, InputContent, ErrorField, LimitCharacters } from "./styles";

import useOutsideClick from '../useOutsideClick';
import EditableElement from './editable-element';

type TextAreaTypes = {
  initialValue: any;
  defaultValue?: string;
  placeholder: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: any;
  className?: string;
  icon?: any;
  limit?: number | null;
  style?: any;
}

const TextArea = ({ initialValue = null, placeholder, error, onChange, className, icon, limit, style }: TextAreaTypes) => {
  const [htmlValue, setHtmlValue] = useState(initialValue) as any;
  const [currentValue, setCurrentValue] = useState(initialValue) as any;
  const [inEdit, setInEdit] = useState(false);
  const [editabledElementRef, setEditabledElementRef] = useState(null) as any;
  
  const handleChange = (value: any) => {
    // if(value === null) return;
    value = String(value);

    onChange({ target: { value } });
    setInEdit(true)
    setCurrentValue(value)
  }

  const clear = () => {
    setCurrentValue("")
    if(editabledElementRef) editabledElementRef.current.innerText = ""
    setInEdit(false)
  }

  useEffect(() => {
    if(initialValue === "" || !initialValue) clear()

    if(initialValue && !currentValue) {
      setInEdit(initialValue?.length > 0)
      setCurrentValue(initialValue)
      setHtmlValue(initialValue)
      if(editabledElementRef) editabledElementRef.current.innerText = initialValue
    }
  }, [initialValue])

  const inputRef = useRef(null) as any;

  useOutsideClick(inputRef, () => {
    if(String(currentValue)?.length < 1 || !currentValue) {
      setHtmlValue(null)
      setInEdit(false)
    }
  })

  return (
    <InputSimplesContainer style={style} ref={inputRef} onClick={() => {
      setInEdit(true)
      if(editabledElementRef) editabledElementRef.current.focus()
    }} className={`${className}${error ? ' error' : ''}`}>
      {icon || ''}
      <InputContent className={className} error={error ? true : undefined} $icon={!icon && 'noIcon'}>
        <EditableElement onChange={handleChange} limit={limit} cb={(elementRef: any) => setEditabledElementRef(elementRef)}>
          <div className="_field textarea" style={{ outline: "none" }}>{htmlValue}</div>
        </EditableElement>
        { placeholder && <label className={`_label${(!inEdit) ? ' noText' : ''}`}>{placeholder}</label> }
      </InputContent>
      <ErrorField state={error ? true : undefined}>{error}</ErrorField>

      { (limit && limit > 0) && <LimitCharacters>{currentValue?.length}/{limit}</LimitCharacters> }
    </InputSimplesContainer>
  )
}

export default TextArea;