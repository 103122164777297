import { useEffect, useRef } from 'react';

import Windows from 'src/client/components/Windows';
import { WrapperTitle } from '../styles';
import Input from 'src/client/components/Inputs/official';
import actions from 'src/client/actions';
import { Button } from 'src/client/styles/layout';
import RoundedButton from 'src/client/components/buttons/rounded';

export default function ManageStaff({ roles, modal, formState, setFormState, close, refreshList }: any) {
  
  async function handleSubmit(e: any) {
    e.preventDefault();
    console.log("1")
    
    const createOrUpdate = modal?.type === "create" ? actions.api.post : actions.api.put;
    console.log("2")
    
    const sendApi = createOrUpdate(`/staffs`, formState);
    console.log("3")
    console.log("5")
  }

  useEffect(() => {
    !modal.state && setFormState({ email: "" });
    
    setFormState({ ...formState, role: roles[1] });
  }, [modal])

  const formRef = useRef<HTMLFormElement>(null);

  return (
    <Windows status={modal.state} title={modal?.type === "create" ? 'Convidar colaborador' : 'Gerenciar Colaborador'} close={close} maxHeight="290px">
      <form ref={formRef} style={{ margin: 0 }} onSubmit={handleSubmit}>
        {modal.type === "create" ? <Input type="email" required={true} placeholder="E-mail do convidado" value={formState?.email} onChange={(e: any) => setFormState({ ...formState, email: e.target.value })} />
        : (
          <WrapperTitle>
            <h2>{formState?.data?.name}</h2>
            <p>{formState?.data?.email}</p>
          </WrapperTitle>
        )}
        <RoundedButton style={{ margin: '20px 0 0 auto' }} text={modal?.type === "create" ? 'Convidar' : 'Salvar Atualizações'} onClick={() => formRef.current?.requestSubmit()} />
      </form>
    </Windows>
  )
}