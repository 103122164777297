import { styled } from "styled-components";
import { Button, Container } from "src/client/styles/layout";
import { ReactComponent as SVGSymbol } from "src/client/assets/illustrations/grupodeouvidos-symbol.svg";

import BackgroundPattern from "src/client/assets/images/background-pattern.png";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
	padding: 120px 0;
    background-image: url(${BackgroundPattern});
    background-size: cover;
	background-blend-mode: luminosity;
    background-position: center;
    background-color: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
	min-height: 100vh;

	.container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 80px;
		align-items: center;
	}

	@media (max-width: 1500px) {
		svg {
			width: 500px;
		}
	}

	@media (max-width: 1200px) {
		svg {
			width: 400px;
		}
	}

	@media (max-width: 991px) {
		padding: 60px 0;
		min-height: auto;

		.container {
			display: flex;
			flex-direction: column-reverse;
			gap: 40px;

			svg {
				--size: 200px;
				height: var(--size);
				min-width: var(--size);
				max-width: var(--size);
			}
		}
	}
`;

const TextWrapper = styled.div`
	color: #5f5f5f;

	max-width: 500px;

	h4, p {
		margin-left: auto;
		margin-right: auto;
	}

	h4 {
		font-size: 2.4rem;
		font-weight: 800;
		line-height: 1.1;

		span {
			font-size: 3.4rem;
			color: var(--color-primary-light);
		}
	}

	p {
		font-size: 1.6rem;
		margin: 50px 0;
	}

	@media (max-width: 1200px) {
		max-width: 480px;

		h4 {
			font-size: 2.2rem;
		}
	}

	@media (max-width: 991px) {

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	@media (max-width: 576px) {
		max-width: 440px;

		h4 {
			font-size: 2rem;

			span {
				font-size: 2.6rem;
			}
		}

		p {
			font-size: 1.4rem;
		}
	}
	
	@media (max-width: 480px) {
		max-width: 360px;

		h4 {
			font-size: 1.6rem;

			span {
				font-size: 2rem;
			}
		}

		p {
			font-size: 1.2rem;
		}
	}

`;

export default function StripeReinforcement()
{
	const navigate = useNavigate();

	return <Wrapper>
		<Container className="container">
			<TextWrapper>
				<h4><span>Há mais de 25 anos</span> cuidando da saúde auditiva com dedicação e carinho!</h4>
				<p>Descubra como ajudamos pessoas a se reconectar com o mundo, oferecendo soluções auditivas personalizadas que transformam vidas.</p>
				<Button onClick={() => navigate("/quem-somos")}>Quero saber mais</Button>
			</TextWrapper>

			<SVGSymbol />
		</Container>
	</Wrapper>
}