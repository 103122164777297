import { styled } from "styled-components";

const Wrapper = styled.div`
  gap: 10px;
  margin: 20px 0 0;
`;

const Option = styled.div<{ $active: number }>`
  font-size: 18px;
  padding: 14px;
  margin-bottom: 6px;

  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  --size: 48px;

  border-radius: 16px;
  background: ${({ $active }) => $active === 1 ? "var(--color-primary-light)" : "var(--color-hover)"};
  border: 2px solid ${({ $active }) => $active === 1 ? "var(--color-primary)" : "transparent"};
  color: ${({ $active }) => $active === 1 ? "#FFF" : "var(--color)"};
  font-weight: 800;

  cursor: pointer;
  transition: 200ms ease-in-out;

  &.active {

  }
`;

type ComponentTypes = {
  options: string[];
  selected: string;
  onChange: (option: string) => void;
}

export default function CheckBox({ options, selected, onChange }: ComponentTypes) {
  return <Wrapper>
    {options?.map((option: string, i: number) =>
      <Option
        key={i}
        $active={option === selected ? 1 : 0}
        onClick={() => onChange(option)}
        >{option}</Option>)}
  </Wrapper>
}