import styled from "styled-components";

export const Wrapper = styled.div`
	.profile {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 40px;

		.initials {
			--picture-size: 45px;
			min-width: var(--picture-size);
			max-width: var(--picture-size);
			height: var(--picture-size);
			border-radius: 100%;
			background-color: var(--color-primary-light);
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
	
			display: flex;
			justify-content: center;
			align-items: center;
	
			font-size: 22px;
			font-weight: 800;
			color: white;
		}

		.info {
			margin: 0 0 0 15px;
			
			h4 {
				font-size: 18px;
				font-weight: 800;
				margin: 0 0 2px;
			}

			span {
				font-size: 16px;
			}
		}
	}

	.data {
		display: flex;
		flex-direction: column;
		margin: 20px 0 0;

		max-width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		&.flex-row {
			flex-direction: row;
		}

		&.align-center {
			align-items: center;
		}

		span {
			font-size: 16px;
			text-overflow: ellipsis;
    		overflow: hidden;

			span {
				font-weight: 800;
				white-space: normal;
			}
		}
	}

	.actions {
		margin: 40px 0 0;
		display: flex;
    	gap: 8px;

		.action {
			--size: 44px;
			min-width: var(--size);
			max-width: var(--size);
			height: var(--size);
			border-radius: var(--size);
			background: var(--color-primary-light);
			padding: 12px;

			cursor: pointer;

			&:hover {
				background: var(--color-primary);
			}

			svg {
				width: 100%;
				height: 100%;
			}

			&.phone, &.email {
				svg path, svg polyline {
					stroke: white;
				}
			}
		}
	}

	.logs {
		margin-top: 30px;
		padding-top: 30px;

		h3 {
			
		}

		.list {
			margin: 30px 0;
			display: flex;
			flex-direction: column;
		}

		span {
			font-size: 14px;
		}
	}
`;

export const LeadLogItem = styled.div<{ active: boolean }>`
	padding: ${({ active }) => active ? '30px 0' : '8px 0'};
	border-top: 1px solid var(--color-border-secondary);
	width: 100%;
	white-space: break-spaces;

	::selection {
		color: var(--color-primary);
		background: var(--color-hover);
	}

	::-moz-selection { /* Code for Firefox */
		color: var(--color-primary);
		background: var(--color-hover);
	}

	.title {
		margin: -10px 0;
		padding: 10px 0;

		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: ${({ active }) => active ? 'initial' : 'pointer'};

		background: transparent;
		transition: 200ms ease-in-out;

		.type {
			font-weight: bold;
		}

		.moment {
			font-size: 14px;
			font-weight: bold;
			opacity: .6;
		}
	}

	span {
		padding: 5px 0;
		background: transparent;
		border-radius: 4px;
		font-size: 17px;
		text-transform: lowercase;
		display: block;
		font-size: 14px;
		word-break: break-all;
	}

	.details {
		padding: ${({ active }) => active ? '20px 0 0' : '0'};
		height: ${({ active }) => active ? 'auto' : '0'};
		overflow: hidden;
		transition: 200ms ease-in-out;
	}

	&.ENVIOSUCESSO,
	&.INSERIDO {
		.title .type {
			color: #00b531;
		}
	}
	&.INSERCAOFALHOU,
	&.ENVIOFALHA {
		.title .type {
			color: #00b531;
		}
	}
`;