import { Context, createContext, useEffect, useState } from "react";
import { destroyCookie, parseCookies } from "nookies";

import { SocketProvider } from "./SocketContext";
import * as configs from 'src/configs';
import { EnumUserRole } from "src/core/enums/EnumUser";
import { IUser } from "src/core/interfaces/IUser";

import Header from "src/client/components/Layout/Header";
import LoadingLine from "../components/Loading/Line";
import MaintenanceSection from "src/client/components/Maintenance";
import { Content, GlobalStyles } from "../styles/globals";
import actions from "../actions";
import usePathname from "../hooks/usePathname";
import useRouter from "../hooks/useRouter";
import Conversations from "../components/Conversations";

export type ConversationsTypes =
{
	visible: boolean;
	section?: any;
	loading?: {
		text: string;
	} | null;
	upload?: {
		file?: File;
		cancel: () => void;
		confirm?: () => void;
		callback: () => void;
	};
	data?: any;
}

type ContextTypes = {
	token: string | null;
	setToken: (token: string | null) => void;
	
	isAuthenticated: boolean;
	user: IUser | null;
	setUser: (user: IUser | null) => void;

	conversations: ConversationsTypes;
	setConversations: (data: ConversationsTypes) => void;

	prepared: boolean;

	loading: boolean;
	setLoading: (state: boolean) => void;

	scroll: number;
	setScroll: (value: number) => void;
	
	isAdmin: boolean;

	notification: NotificationTypes;
	setNotification: (data: NotificationTypes) => void;
}

export type NotificationTypes = {
	messages?: boolean
}

export const ApplicationContext: Context<ContextTypes> = createContext({} as ContextTypes);

export const ApplicationProvider = ({ children, title, isDashboard }: any) => {

	const defaultToken = parseCookies()["token"];
	const [prepared, setPrepared] = useState(false);

	const [loadingLine, setLoadingLine] = useState(false);
	const [scroll, setScroll] = useState(0);

	const [token, setToken] = useState<string | null>(defaultToken);
	const [user, setUser] = useState<IUser | null>(null);
	
	const pathname = usePathname();
	const router = useRouter();

	useEffect(() => {
		// if (!user) destroyCookie(undefined, "token");
		// if (user && token) api.defaults.headers["Authorization"] = `Bearer ${token}`;

		document.getElementById("header")?.scrollIntoView({ behavior: "smooth", block: "start" });
	}, [user])

	const [notification, setNotification] = useState<NotificationTypes>({});
	const [loading, setLoading] = useState<boolean>(false);

	// Conversations API
	const [conversations, setConversations] = useState<ConversationsTypes>({ visible: false });

	async function getUser()
	{
		try 
		{
			if (!isDashboard) throw "";

			if (token)
			{
				actions.api.defaults.headers["Authorization"] = `Bearer ${token}`;

				await actions.api.get("/users/verify-token").then(response => {
					
					const _user = response.data.user as IUser;
					if (_user) setUser(_user);

					if(_user.role !== EnumUserRole.Cliente && !pathname.startsWith("/admin")) router("/admin");
					
				}).catch(e => {
					console.log(e.response.status)
					if (e.response.status === 403)
					{
						destroyCookie(undefined, 'token', {
							path: "/"
						});
					}
				});
			}
		}
		catch (e) 
		{
			// Tratar
		}
		finally 
		{
			setPrepared(true);
		}
	}

	useEffect(() => {
		getUser();
	}, [])

	if(configs.MAINTENANCE && !!user) {
		return <>
			<Header title="Manutenção" isDashboard={false} />
			<MaintenanceSection />
		</>
	}

	const isAuthenticated = !!token && !!user;

	return <ApplicationContext.Provider value={{
		
			token,
			setToken,
			
			isAuthenticated,
			user,
			setUser,
			isAdmin: !!user && user.role > 0,

			conversations,
			setConversations,

			prepared,

			loading,
			setLoading,

			scroll,
			setScroll,

			notification,
			setNotification
		}}>
		<SocketProvider>
			{ isAuthenticated ? <Conversations close={() => setConversations({ visible: false })} /> : <></> }
			<LoadingLine active={loadingLine} />
			<Header title={title} isDashboard={isDashboard} />
			<Content $prepared={prepared}>{children}</Content>
		</SocketProvider>
		<GlobalStyles />
	</ApplicationContext.Provider>
}