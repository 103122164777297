import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import analytics from "src/client/analytics";
import { ApplicationProvider } from "src/client/contexts/ApplicationContext";

export default function InstitutionalLayoutRoot({ children, title }: any)
{
	const location = useLocation();

	useEffect(() => {
		analytics.pageview(location.pathname + location.search);
	}, [location]);

	return <>
	  <ApplicationProvider title={title}>
		{children}
	  </ApplicationProvider>
	</>
}