import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button, Container } from "src/client/styles/layout";
import { examesAudiologicos } from "../../data/exames-audiologicos";

const Wrapper = styled.div`
    background: #F7F7F7;
    padding: 100px 0;

    h2 {
        font-size: 2.4rem;
        font-weight: 800;
        margin: 0 0 80px;
        text-align: center;
    }

    @media (max-width: 992px) {
        h2 {
            font-size: 2rem;
        }
    }

    @media (max-width: 576px) {
        padding: 60px 0;
        
        h2 {
            font-size: 1.8rem;
            max-width: 250px;
            margin: 0 auto 60px;
        }
    }

`;

const List = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

    @media (max-width: 1400px) {
        gap: 20px;
    }

    @media (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
`;

const Item = styled.div`
    border-radius: 20px;
    background-color: #E8E9ED;
    border: 1px solid rgb(191 191 191 / 10%);
    overflow: hidden;
    cursor: pointer;

    display: flex;
    flex-direction: column;

    img {
        width: 100%;
        height: 30vw;
        max-height: 250px;
        
        object-fit: cover;

        transition: .2s ease-in-out;
    }

    &:hover {
        img {
            transform: scale(1.2);
        }
    }

    .info {
        background-color: #FFF;
        padding: 30px;
        flex: 1;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;

        z-index: 1;

        h3 {
            font-size: 1.3rem;
            font-weight: 800;
            margin: 0 0 30px;
            max-width: 300px;
            flex: 1;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        p {
            font-size: 1.2rem;
            opacity: .9;
        }

        Button {
            font-size: 1.2rem;
            max-width: 100%;
            width: 100%;
        }
    }

    @media (max-width: 1200px) {
        .info {
            h3 {
                font-size: 1.2rem;
            }

            Button {
                font-size: 1rem;
            }
        }
    }

    @media (max-width: 768px) {
        .info {
            padding: 20px;

            h3 {
                font-size: 1rem;
            }

            Button {
                padding: 14px 20px;
            }
        }
    }

    @media (max-width: 576px) {
        border-radius: 10px;
        
        .info {
            padding: 10px;

            h3 {
                font-size: .8rem;
                font-weight: 800;
                margin: 10px 0 20px;
            }

            Button {
                font-size: .8rem;
                padding: 14px 10px;
            }
        }
    }
`;

export default function ExamesAudiologicosList()
{
    const navigate = useNavigate();

    return <Wrapper>
        <Container>
            <h2>Exames Audiológicos</h2>
            <List>
                { examesAudiologicos.map((item: any, i: number) =>
                    <Item key={i}>
                        <img src={item.image} alt={item.name} />
                        <div className="info">
                            <h3>{item.name}</h3>
                            <Button onClick={() => navigate("/agendamento")}>Quero agendar</Button>
                        </div>
                    </Item>
                ) }
            </List>
        </Container>
    </Wrapper>
}