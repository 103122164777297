import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button, Container } from "src/client/styles/layout";
import { depoimentos } from "../../data/depoimentos";

const Wrapper = styled.div`
    background: #F7F7F7;
    padding: 100px 0;

    h2 {
        font-size: 40px;
        font-weight: 800;
        margin: 0 0 80px;
        text-align: center;

        span {
            color: var(--color-primary-light);
            display: block;
        }
    }

    h4 {
        margin: 100px auto 40px;
        text-align: center;
        max-width: 640px;
        font-size: 2rem;
        font-weight: 700;
        line-height: .2s
    }

    .container Button {
        margin: 0 auto;
        padding-left: 80px;
        padding-right: 80px;
    }

    @media (max-width: 992px) {
        padding: 80px 0;

        h2 {
            font-size: 2rem;
        }

        h4 {
            max-width: 480px;
            font-size: 1.6rem;
        }
    }

    @media (max-width: 576px) {
        padding: 60px 0;
        
        h2 {
            font-size: 1.8rem;
            max-width: 320px;
            margin: 0 auto 60px;
        }

        h4 {
            max-width: 340px;
            font-size: 1.5rem;
        }

        .container Button {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    @media (max-width: 480px) {
        h2 {
            font-size: 1.6rem;
            max-width: 320px;
        }
        h4 {
            max-width: 320px;
            font-size: 1.4rem;
        }
    }
`;

const List = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;

    @media (max-width: 1400px) {
        gap: 30px;
    }

    @media (max-width: 1200px) {
        gap: 20px;
    }

    @media (max-width: 991px) {
        gap: 15px;
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 480px) {
        margin: 0 -10px;
    }
`;

const Item = styled.div`
    background-color: black;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;

    color: var(--color-white);

    iframe {
        object-fit: cover;
        width: 100%;
        transition: .3s ease-in-out;
        border: none;
        height: 25vw;
    }

    &:hover {
        img {
            transform: scale(1.2);
        }
    }

    .info {
        padding: 30px;

        h3 {
            font-size: 1.4rem;
            font-weight: 800;
            color: var(--color-primary-light);
            margin: 0 0 10px;
        }

        p {
            font-size: 1.2rem;
            opacity: .9;
        }
    }

    @media (max-width: 768px) {
        iframe {
            height: 85vw;
        }
    }
`;

export default function DepoimentosVideosList()
{
    const navigate = useNavigate();

    return <Wrapper>
        <Container className="container">
            <h2>Confira os depoimentos <span>dos nosso pacientes</span></h2>
            <List>
                { depoimentos.map((item: any, i: number) =>
                    <Item key={i}>
                        <iframe src={`https://www.youtube.com/embed/${item.youtubeCode}?rel=0&modestbranding=1&controls=1`} title="Depoimento - Grupo Dê Ouvidos" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        <div className="info">
                            <h3>{item.name}</h3>
                            { item.paragraphs.map((paragraph: string, _i: number) => <p key={_i}>{paragraph}</p>) }
                        </div>
                    </Item>
                ) }
            </List>

            <h4>Seja o próximo a reconectar-se com a vida, nós estamos aqui para te ajudar!</h4>
            <Button onClick={() => navigate("/orcamento")}>Quero me reconectar</Button>

        </Container>
    </Wrapper>
}